import './Blurb.css'
import bgTopLeft from './images/bg-top-left.svg';

function Blurb() {
  return (
    <section className='blurb'>
      <img src={bgTopLeft} className='bg-top-left' alt='BgTopLeft' />
      <div className='container'>
        <div className='title semibold color-purple'>Are you ready to take control of your financial future?</div>
        <div className='subheading regular'>
          <p>
          Master the essential skills of effective money management, tackle credit card debt and financial pitfalls, and establish a solid budget to take control of your finances. 
          </p>
          <p>
          Equip your family for the future with this foundational financial literacy class – lay the groundwork for a secure and prosperous tomorrow starting today.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Blurb;