import './FamilyFinancialWorkshop.css'
import familyFinancialImg from './images/familyfinancialWorkshop.jpg';

function FamilyFinancialWorkshop() {
    return (
        <section className='family-financial-workshop centered'>
          <div className='container'>
            <div className='small-title color-light-gray'>ADDITIONAL CLASS OFFERED</div>
            <div className='title'>Family Financial Workshop</div>
						<div className='subtitle'>
							There are many good reasons for family members to talk about money and 
							its handling before it's too late.
						</div>
            <div className='description-container'>
							<img src={familyFinancialImg} alt='FamilyFinancialImg' />
							<div className='description'>
								<div className='semibold color-light-gray'>WORKSHOP GOALS:</div>
								<ul>
									<li>
										To educate all family members about the value of money so that they can be 
										financially aware and financially responsible.
									</li>
									<li>
										To create an environment for parent-children communication regarding family 
										finances without negative emotion.
									</li>
									<li>
										To help build a financially stable generation.
									</li> 
								</ul>
							</div>
						</div>
          </div>
        </section>
    )
}

export default FamilyFinancialWorkshop;