import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import ScrollToTop from './common/ScrollToTop';
import Header from './header/Header';
import Home from './home/Home';
import Footer from './footer/Footer';
import Phong from './phong/Phong';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import Modal from "react-modal";


Modal.setAppElement("#root");
Amplify.configure(config);


function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/phong-nguyen' element={<Phong />} />
    </Routes>
  )
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <AppRoutes />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
