import React from 'react'
import './Signup.css'
import calendarImg from './images/calendar.svg'
import clockImg from './images/clock.svg'
import locationImg from './images/location.svg'
import checkImg from './images/check-circle.svg';
import test from './images/sera-dots.png'
import bgTopRight from './images/bg-top-right.svg';
import PhoneInput from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Modal from 'react-modal';
import { API } from "aws-amplify";
import { createSignup } from '../graphql/mutations'

function Signup({signupFormRef}) {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [goals, setGoals] = React.useState('');
  const [errorFirstName, setErrorFirstName] = React.useState(false);
  const [errorLastName, setErrorLastName] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorPhoneNumber, setErrorPhoneNumber] = React.useState(false);
  const [errorSubmit, setErrorSubmit] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const onFirstNameChange = (e) => {
    if (e.target.value.length)
      setErrorFirstName(false);
    else
      setErrorFirstName(true);
    setFirstName(e.target.value)
  }

  const onLastNameChange = (e) => {
    if (e.target.value.length)
      setErrorLastName(false);
    else
      setErrorLastName(true);
    setLastName(e.target.value)
  }

  const onEmailChange = (e) => {
    if (e.target.value.length && isEmailValid(e.target.value))
      setErrorEmail(false);
    else
      setErrorEmail(true);
    setEmail(e.target.value)
  }

  const onPhoneNumberChange = (p) => {
    if (p && p.length && isValidPhoneNumber(p))
      setErrorPhoneNumber(false);
    else
      setErrorPhoneNumber(true);
    setPhoneNumber(p);
  }

  const onGoalsChange = (e) => {
    setGoals(e.target.value)
  }

  const isEmailValid = (email) => {
    if (String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        return true;
      }
      return false;
  };

  const isSubmitDisabled = () => {
    return (firstName.length === 0 || lastName.length === 0 || !isEmailValid(email) || !isValidPhoneNumber(phoneNumber) || isSubmitting);
  }

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setGoals('');
    setErrorFirstName(false);
    setErrorLastName(false);
    setErrorEmail(false);
    setErrorPhoneNumber(false);
    setErrorSubmit(false);
  }

  const submitForm = async () => {
    if (isSubmitting) {
      return
    }

    setErrorSubmit(false);
    setIsSubmitting(true);

    let signup = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      financial_goals: goals
    }

    try {
      await API.graphql({ 
        query: createSignup, 
        variables: { input: signup }
      });
    } catch (err) {
      console.error("Error Message: ", err);
      setIsSubmitting(false); 
      setErrorSubmit(true);
      return;
    }; 

    setIsSubmitting(false); 
    resetForm(); 
    setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false);
  }

  return (
    <section ref={signupFormRef} className='signup centered'>
      <img src={bgTopRight} className='bg-top-right' alt='BgTopRight' />
      <div className='container'>
        <div className='subheading semibold color-pink'>FREE CLASS</div>
        <div className='title semibold color-purple'>Mastering money basics</div>
        {/* Hide details for now 
        <div className='details centered'>
          <div className='detail'>
            <img src={calendarImg} alt='Calendar' />
            <div>Saturday, October 28</div>
          </div>
          <div className='detail'>
            <img src={clockImg} alt='Clock' />
            <div>10:00 am</div>
          </div>
          <div className='detail'>
            <img src={locationImg} alt='Location' />
            <div>San Jose area</div>
          </div>
        </div>
        */}
        <form>
          <div className='field'>
            <div>
              <input id="firstName" type="text" name="firstName" placeholder="Enter first name" 
                value={firstName} onChange={onFirstNameChange} onBlur={onFirstNameChange} />
              {errorFirstName && (
                <div className="field-error">Please enter a valid first name</div>
              )}
            </div>
          </div>
          <div className='field'>
            <div>
              <input id="lastName" type="text" name="lastName" placeholder="Enter last name"
                value={lastName} onChange={onLastNameChange} onBlur={onLastNameChange} />
              {errorLastName && (
                <div className="field-error">Please enter a valid last name</div>
              )}
            </div>   
          </div>  
          <div className='field'>
            <div>
              <input id="email" name="email" type="email" placeholder="Enter email" 
                value={email} onChange={onEmailChange} onBlur={onEmailChange} />
              {errorEmail && (
                <div className="field-error">Please enter a valid email</div>
              )}
            </div>
          </div>
          <div className='field'>
            <div>
            <PhoneInput
              className='phone-input'
              country="US"
              placeholder='Enter phone number'
              value={phoneNumber}
              onChange={onPhoneNumberChange} />
              {errorPhoneNumber && (
                <div className="field-error">Please enter a valid phone number</div>
              )}
            </div>
          </div>
          <div className='field'>
            <div>
              <textarea id="goals" name="goals" value={goals} onChange={onGoalsChange}
                placeholder="Tell us about your financial goals. (optional)" />
            </div>
          </div>

          <p className='display-small color-light-gray'>We will never sell your personal information and will use it only to keep you updated about the class.</p>

          <div className='processing-button-container'>
            <button className={`${isSubmitting ? 'processing' : ''}`} disabled={isSubmitDisabled()} onClick={() => submitForm()}>
              Sign up
            </button>
            <img src={test} className={`rotation ${isSubmitting ? 'show' : 'hide'}`} alt='' />
          </div>
          {errorSubmit && (
            <div className="field-error">There were errors saving your information. Please try again later.</div>
          )}
        </form>

      </div>
      <Modal
          isOpen={showModal}
          onRequestClose={closeModal}
          contentLabel="My dialog"
          className="modal"
          overlayClassName="modal-background"
          closeTimeoutMS={3000}
          >
            <div className='container'>
              <div className='subheading semibold color-pink'>FREE CLASS</div>
              <div className='title semibold color-purple'>Mastering money basics</div>
              <img src={checkImg} alt='CheckCircle'/>
              <div className='subtitle'>Thank you for enrolling in our class!</div>
              <div className='description'>
              We're thrilled to have you on board. Keep an eye on your inbox for an email with all the essential information for the upcoming class. 
              </div>
            {/* Hide details for now
              <div className='details centered'>
                <div className='detail'>
                  <img src={calendarImg} alt='Calendar' />
                  <div>Saturday, October 28</div>
                </div>
                <div className='detail'>
                  <img src={clockImg} alt='Clock' />
                  <div>10:00 am</div>
                </div>
                <div className='detail'>
                  <img src={locationImg} alt='Location' />
                  <div>San Jose area</div>
                </div>
              </div>
             */}  
              <button onClick={() => closeModal()}>Got it!</button>
            </div>
      </Modal>
    </section>
  )
}

export default Signup;