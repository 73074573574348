
import './Home.css';
import TopBanner from "../banner/Banner";
import Hero from "../hero/Hero";
import Blurb from "../blurb/Blurb";
import Instructor from "../instructor/Instructor";
import Syllabus from "../syllabus/Syllabus";
import Sponsor from "../sponsor/Sponsor";
import Signup from "../signup/Signup";
import FamilyFinancialWorkshop from '../other/FamilyFinancialWorkshop';
import React from "react";
import { publish, subscribe, unsubscribe } from '../common/Events';

function Home() {
  const bannerContainerRef = React.useRef();
  const blurbContainerRef = React.useRef();
  const signupFormRef = React.useRef();

  const handleScroll = React.useCallback((event) => { 
    if (typeof window === "undefined" || !bannerContainerRef.current) {
      return;
    }
    const blurbOnTop = blurbContainerRef.current.getBoundingClientRect().top <= 200;
    bannerContainerRef.current.style.display = blurbOnTop ? 'none' : 'block';

    if (blurbOnTop) {
      publish("showHeaderSignupButton");
    } else {
      publish("hideHeaderSignupButton");
    }

  }, []);

  React.useEffect(() => {
    subscribe("scrollToSignupForm", () => scrollToSignupForm());

    return () => {
      unsubscribe("scrollToSignupForm", () => scrollToSignupForm());
    }
  }, []);

  React.useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    handleScroll();
    window.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  function scrollToSignupForm() {
    window.scrollTo({top: signupFormRef.current.offsetTop - 100, behavior: 'smooth'})
  }

  return (
    <div className='home'>
    {/* Hide top banner for now
     <div ref={bannerContainerRef} className='banner-container'>
      <TopBanner scrollToSignupForm={scrollToSignupForm} />
    </div>
    */}
    <Hero scrollToSignupForm={scrollToSignupForm} />
    <div ref={blurbContainerRef}>
      <Blurb />
    </div>
    <Instructor />
    <Syllabus scrollToSignupForm={scrollToSignupForm} />
    <Sponsor />
    <Signup signupFormRef={signupFormRef} />
    <FamilyFinancialWorkshop />
    </div>
  )
}

export default Home;