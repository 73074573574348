import './Hero.css';
import heroImg from "./images/hero.svg";
import bgTopLeft from './images/bg-top-left.svg';
import bgBottomRight from './images/bg-bottom-right.svg';

function Hero({scrollToSignupForm}) {
  return (
    <section className='hero centered'>
      <img src={bgTopLeft} className='bg-top-left' alt='BgTopLeft' />
      <img src={bgBottomRight} className='bg-bottom-right' alt='BgBottomRight' />
      <div className='container centered'>
        <div className='description'>
          <h2 className='semibold color-light-pink'>MASTERING MONEY BASICS</h2>
          <div className='display-semibold color-neutral-white'>
            <span className='color-light-pink'>FREE</span> financial class to achieve a <span className='color-light-pink'>debt-free life.</span>
          </div>
          <h3 className='regular color-neutral-white'>If you find yourself juggling income and financial obligations without finding the right balance, this class is for you! </h3>
          <button onClick={() => scrollToSignupForm()}>Sign up</button>
        </div>
        <img src={heroImg} alt='Hero' />
      </div>
    </section>
  )
}

export default Hero;