import { Link } from 'react-router-dom';
import './Instructor.css';
import instructorImg from "./images/phong.svg";

function Instructor() {
  return (
    <section className='instructor centered'>
      <div className='container centered'>
        <div className='img-container'>
          <img src={instructorImg} alt='Instructor' />
        </div>
        <div className='description'>
          <div className='title semibold color-purple'>Meet your instructor: Phong Nguyen</div>
          <div className='subheading regular'>
            <p>
            Phong Nguyen is an entrepreneur with a passion for helping people achieve financial freedom. 
            </p>
            <p>
            Born in Vietnam and arriving in the U.S. in 1975, he worked tirelessly to fund his education and purchase his first home at 23. Despite earning a good income, he struggled to navigate his financial path until he cracked the code to financial independence in 2001, retiring at 42.
            </p>
          </div>
          <button className='purple'><Link to='/phong-nguyen' className='link'>Learn more</Link></button>
        </div>
      </div>
    </section>
  )
}

export default Instructor;