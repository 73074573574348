import './Syllabus.css'
import syllabusImg from './images/syllabus.svg'
import bgBottomRight from './images/bg-bottom-right.svg';

function Syllabus({scrollToSignupForm}) {
  return (
    <section className='syllabus centered'>
      <img src={bgBottomRight} className='bg-bottom-right' alt='BgBottomRight' />
      <div className='container centered'>
        <div className='img-container'>
          <img src={syllabusImg} alt='Syllabus' />
        </div>
        <div className='description'>
          <div className='title semibold'> What you’ll learn</div>
          <div className='subheading regular'>
            1.&nbsp;&nbsp;Introduction to financial literacy<br/>
            2.&nbsp;&nbsp;Budgeting and money management<br/>
            3.&nbsp;&nbsp;Debt management - focus on credit card debt<br/>
            4.&nbsp;&nbsp;Saving and investing<br/>
            5.&nbsp;&nbsp;Banking and credit monitoring<br/>
            6.&nbsp;&nbsp;Financial planning and goal setting<br/>
            7.&nbsp;&nbsp;Optional: Insurance and risk management<br/>
            8.&nbsp;&nbsp;Optional: Future financial preparedness<br/>
            9.&nbsp;&nbsp;Assessment
          </div>
          <button onClick={() => scrollToSignupForm()}>Sign up</button>
        </div>
        <img src={syllabusImg} alt='Syllabus' />
      </div>
    </section>
  )
}

export default Syllabus;