import './Footer.css'
import logo from './images/logo-white.svg'

function Footer() {
  return (
    <section className='footer'>
      <div className='container'>
        <div className='description'>
          <img src={logo} alt='LogoWhite' />
          <p className='display-medium regular color-neutral-white'>
          Take control of your personal finance with enabling tools and strategies. Boost your credit and eliminate your debt, faster than ever before.
          </p>
        </div>
        <div className='bottom display-medium color-neutral-white'>
          <p className='display-medium semibold color-neutral-white'>Financial freedom. Simplified.</p>
          <div className='disclaimer'>
            <p>
              © 2023 Sera | Financial Freedom. Simplified. All rights reserved, Era-X Corporation dba Sera.
            </p>
            <p>
              Disclaimer: This is for educational purposes. Your results may vary, restrictions apply.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer;