import './Phong.css';
import phong from './images/phong.svg';
import React from 'react';
import { Helmet } from 'react-helmet';

function Phong() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Sera - Founder's Story</title>
      </Helmet>
      <section className='phong' >
        <div className='title'>Founder&apos;s Story</div>
        <p className='headline'>
          I&apos;m on a mission: to enable you and as many others as possible to live a better life without the financial stress that most Americans face. I&apos;m asking for your support in building Sera, the first financial wellness platform that provides the simplicity and power to enable people to achieve sustainable financial freedom - as quickly as possible.
        </p>
        <p>
          I&apos;ve always believed that we are all able to make money. It&apos;s necessary for survival and so we&apos;ve been trained, educated and attuned on an infinite number of paths. Some make more than others, but we&apos;ve all been conditioned to make a living in some way, and to believe that simply making more money will make our financial worries disappear. 
        </p>
        <p>
          I don&apos;t believe that&apos;s true - because we&apos;ve also been conditioned to spend at or beyond our income, regardless of how much we make. This is why two-thirds of American households are struggling to pay off their debt and roughly one third of multi-million dollar lottery winners eventually go broke. In short, it&apos;s not how much money you earn, but what you do with it.
        </p>
        <p>
          So how can people become financially free? I&apos;ve developed a simple formula over my lifetime and have been sharing it on a small scale over the last decade in classes in the Bay Area. I&apos;ve taught this method to hundreds of participants who&apos;ve each made significant progress toward their goals of getting out of debt and achieving financial freedom. Now through Sera, I want to share it with you.
        </p>
        
        <p className='phong-who-is'>
          <img alt='phong' />
          <div className='title'>Who is Phong Nguyen?</div><br/>
          Here&apos;s a little about me. I was born in Vietnam and came to the US in 1975 when my country collapsed. During high school in Hawaii, I worked as a janitor to earn enough money for a one-way ticket to California in order to establish residency to lessen the cost of going to college there. Throughout college I worked various jobs and earned grants to finish my degree. I bought my first house at 23.
        </p>
        <p>
          Still, I was pretty clueless how to navigate my financial path and how to get to the next level and achieve my goal of becoming financially free, even if I earned more and more money over the next 10 or 20 years. As hard as I tried to improve my financial picture, I had no idea where I was on my financial journey or how long it would take to get there.
        </p>
        <p>
          After repeated attempts and some major failures in many types of investments (stocks, commodities, oil and gas, small businesses and real estate), I finally figured it out. Mind you, I&apos;m not loaded with money or living lavishly, but I was able to retire early at the age of 42.  Now I get to choose what I want to do, versus what I have to do each day.  By the way, that was in 2001 and I&apos;m still driving my faithful 2001 pickup truck today. That was the year I became financially free.
        </p>
        <br/>
        <p>
          <div className='title'>So what&apos;s my financial freedom roadmap?</div><br/>
          It&apos;s really quite simple but very difficult to achieve - 1). live a debt free lifestyle, 2). accumulate adequate assets and 3). achieve optimal passive income.
        </p>
        <p>
          Becoming debt-free means no more credit card debt, student or car loans, or any other high interest rate debt. Accumulating adequate assets means that your stocks, real estate holdings, cash etc. must be worth between 20 to 30 times the annual income you need to live comfortably wherever you choose. For someone in San Jose, CA where I live, that number will be much higher than someone living in areas with a lower cost of living. Finally, achieving optimal passive income is generating enough earnings from your passive investments to pay for all your monthly expenses, plus 20% for emergency savings.
        </p>

        <p>
          Going a bit further into the topic of assets and passive income, I believe having adequate assets is key to your financial &apos;status&apos;, but it&apos;s not really feasible to live off your assets as many of them, like your home or other real estate, 401ks before a certain age and stocks etc. are not easily converted to cash to spend on daily expenses. Nor can you count on these assets to deal with unexpected challenges that life may present. So unlike many financial advisors who recommend simply amassing large assets, my roadmap recommends that you need to have optimal passive income as well. Of course you can make adjustments to both of these goals, but having a sound level of assets and passive income together generally offers much stronger financial stability.
        </p>
        <p className='headline'>
          So now you have the roadmap, but how do you tackle each of those three goals as quickly as possible? As I mentioned, we all know how to make money, but very few of us are trained or know how to leverage your existing credit standing to save better or invest more wisely to build our financial future faster. But we all know the sooner we start saving and investing, the better shot we have at achieving a sustainable financial future.
        </p>
        <p>
          Here comes the difficult part. The existing financial industry and all its tools are not designed to enable people to be in control of their financial standing. We all are instruments of the lending practice such that the moment we use any credit product, we are in debt. Furthermore, we are so busy with work, family, community, social obligations and interests that we don&apos;t have the time to do the research or take the necessary actions to save more and invest better. Or, perhaps we just don&apos;t have the determination to stay the course given these challenges, or because of lack of experience or access, we just haven&apos;t built those muscles.
        </p>
        <p>
          As life goes on, our obligations and responsibilities become larger and larger, putting most of us into a vicious cycle of work to pay down debts, and losing more and more of the one thing that is most precious - time.
        </p>
        <div style={{textAlign: 'center'}}>
          <img className='financial-lifecycle' alt='' />
        </div>
        <p>
          Now some of us may be good at saving and have a fair bit of money set aside for the future, but where you put that savings is so critical. You have to invest “wisely” so that money works for you, earns interest or dividends, and grows so that you don&apos;t need to work someday. But how do you pick the right investments? It&apos;s a complicated set of choices, and the stock market has become the default. But as we know, the market is volatile and it is just as easy to win as it is to lose, especially in the short run. And over the long run, the market has yielded only 5-8% on average annually. That is not enough to bet your financial future on. It alone won&apos;t get you where you want to be, and if it does, it won&apos;t be nearly as fast as it could be.
        </p>
        <p>
          Combined with our hectic lifestyles, the fact that it&apos;s so difficult to save faster and optimally invest that savings is the biggest roadblock to achieving financial freedom.
        </p>
        <p>
          This is where Sera comes in. We&apos;re building the first holistic financial wellness platform that simplifies and automates your journey toward financial freedom using the formula I personally used and have been sharing with my students over the last decade. Sera&apos;s AI and patent-pending technology helps you to get out of debt faster and at less interest expense than other traditional methods. And once out of debt, Sera helps you save more quickly and invest more effectively in assets that generate passive income.
        </p>
        <p>
          Sera will do research and personalize a program that matches your financial picture at any stage of your journey. Sera will also automate most of the work while guiding you every step of the way.
        </p>
        <br/>
        <p>
          <div className='title'>What is my motivation?</div><br/>
          So now you may be asking yourself, if I&apos;m retired, why are my team and I building Sera?
        </p>
        <p>
          You see, when I was 10 years old, I joined the Vietnamese Boy Scouts. Each Scout was encouraged to pick a motto, to be approved by the Troupe Leader, and mine was to add value to anyone I come in contact with. We swore an oath to keep our Boy Scout honor, and to live by our motto. So as long as I can remember, my life has been a constant effort to add value to others. Some I had great success with, others maybe not as much I would have liked. I&apos;ve realized that Sera is my biggest opportunity to add value to millions of people who might not be able to otherwise become financially well. So, join me in this new journey. Together we can change the way the financial industry serves the masses.
        </p>
        <br/>
        <p>- Phong Nguyen</p>

      </section>
    </React.Fragment>
  );
};

export default Phong;  
