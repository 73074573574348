import './Sponsor.css'
import sponsorImg from './images/patelco.svg'

function Sponsor() {
  return (
    <section className='sponsor centered'>
      <div className='container'>
        <div className='subheading semibold'>On-site classes sponsored by:</div>
        <img src={sponsorImg} alt='Sponsor' />
      </div>
    </section>
  )
}

export default Sponsor;