import './Header.css';
import React from 'react';
import logo from "./images/logo.svg";
import { Link } from "react-router-dom";
import { publish, subscribe, unsubscribe } from '../common/Events';

function Header() {
  const signupButtonRef = React.useRef();

  React.useEffect(() => {
    subscribe("showHeaderSignupButton", () => setSignupButtonVisibility(true));
    subscribe("hideHeaderSignupButton", () => setSignupButtonVisibility(false));

    return () => {
      unsubscribe("showHeaderSignupButton", () => setSignupButtonVisibility(false));
      unsubscribe("hideHeaderSignupButton", () => setSignupButtonVisibility(true));
    }
  }, []);

  function setSignupButtonVisibility(visible) {
    signupButtonRef.current.style.display = visible ? 'block' : 'none';
  }

  return (
    <section className='header'>
      <Link to='/' className='logo'>
        <img src={logo} alt='Logo' />
      </Link>
      <button ref={signupButtonRef} onClick={() => publish("scrollToSignupForm")}>Sign up</button>
    </section>
  )
}

export default Header;